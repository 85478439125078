import { Text, YStack, Theme, ScrollView, Button } from '@bp/ui'
import React from 'react'
import Protected from '../layout/protected'
import { useUserInfoStore } from 'app/store'
import Feed from './feed'
import NewPost from './newPost'

export default function Feeds({ label }) {
  const userId = useUserInfoStore((s) => s.id)

  return (
    <Theme name="light">
      <ScrollView bc="$background">
        <Protected>
          <YStack justifyContent="center">
            <YStack p="$4">
              {label && <Text variant="H1">{label}</Text>}
              <NewPost userId={userId} feedSlug="timeline" />
              <Feed userId={userId} feedSlug="timeline" />
            </YStack>
          </YStack>
        </Protected>
      </ScrollView>
    </Theme>
  )
}
