import { useState } from 'react'
import API from 'app/api'
import { SolitoImage } from 'solito/image'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Avatar, Spinner, TextField, XStack, YStack } from '@bp/ui'
import { useUserInfoStore } from 'app/store'
import { SendIcon, InsertPhotoIcon } from '@bp/ui/src/Iconsx'
import * as ImagePicker from 'expo-image-picker'
import Post from './post'

function NewPost({ userId, feedSlug }) {
  const queryClient = useQueryClient()
  const avatar = useUserInfoStore((state) => state.avatar)
  const [postText, setPostText] = useState()
  const [postPreview, setPostPreview] = useState()

  const addPostMutation = useMutation({
    mutationFn: ({ postText, type, images }) => API.post.createPost(postText, type, images),
    onSuccess: async (response) => {
      const newPost = {
        pending: true,
        activities: [
          {
            actor: { data: response.post.user },
            object: JSON.stringify(response.post),
          },
        ],
      }
      await queryClient.cancelQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
      setPostPreview(newPost)
      setPostText('')
      setPicture(undefined)
    },
    onSettled: () => {
      // We cannot re-fetch because the new post is not yet in the GS database.
      //queryClient.invalidateQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
    },
  })

  const createNewPost = async () => {
    try {
      const type = picture ? 'photo' : 'text'
      const images = type === 'photo' ? [`data:image/png;base64,${picture.base64}`] : undefined
      addPostMutation.mutate({ postText, type, images })
    } catch (e) {}
  }

  const [picture, setPicture] = useState(undefined)
  const onUploadImage = async () => {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        return
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true,
      })
      if (!result.canceled) {
        setPicture(result.assets[0])
      }
    } catch (e) {}
  }

  return (
    <YStack>
      <YStack
        backgroundColor="$light"
        paddingHorizontal="$4"
        paddingVertical="$4"
        borderRadius="$4"
        marginBottom="$4"
        gap="$4"
      >
        <XStack gap="$3" alignItems="flex-start">
          <Avatar circular size="$5" src={avatar?.md} />
          <YStack flex={1}>
            <TextField
              flex={1}
              value={postText}
              placeholder="What's up?"
              onChangeText={(value) => setPostText(value)}
              inputProps={{ paddingRight: 50, multiline: true }}
            />
            <Button
              onPress={onUploadImage}
              style={{ position: 'absolute', right: 0, top: 8, zIndex: 999 }}
              icon={<InsertPhotoIcon size="$1.2" color="$secondaryDarker" />}
            />
          </YStack>
        </XStack>
        {picture && (
          <XStack justifyContent="center">
            <SolitoImage
              alt="Post image"
              key="imageUpload"
              width={320}
              height={300}
              src={picture.uri}
            />
          </XStack>
        )}
        <XStack justifyContent="flex-end">
          <Button
            variant="primary"
            disabled={addPostMutation.isPending}
            onPress={createNewPost}
            iconAfter={addPostMutation.isPending ? Spinner : SendIcon}
          >
            Post
          </Button>
        </XStack>
        {/* <XStack
        backgroundColor="red"
        $gtSm={{
          backgroundColor: 'blue',
        }}
      >
        <Button>Hello</Button>
      </XStack> */}
      </YStack>
      {postPreview && <Post post={{ item: postPreview }} />}
    </YStack>
  )
}

export default NewPost
